<template>
  <div class="container">
    <nuxtChild />
  </div>
</template>

<script>
import pageMeta from '~/mixins/pageMeta';

export default {
  name: 'ServicesPageWrapper',
  mixins: [pageMeta],
  async asyncData({ $api, route }) {
    const { title: pageTitle, data: pageMetaData } = await $api.general.getPageMeta(route.path);

    return {
      pageTitle,
      pageMetaData,
    };
  },
}
</script>
